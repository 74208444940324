const QUERY_PARAMS = {
    STATE: "state",
    BUSINESS_ID: "business_id",
    USER_ID: "user_id",
    ID: "id",
    CODE: "code",
    REDIRECT_URI: "redirect_uri",
    MARKETING_AGREEMENT: "is_marketing_agreed"
}

export default QUERY_PARAMS;