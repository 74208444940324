<template>
  <div>
    <router-view class="view"/>
    <WaterMark />
  </div>
</template>

<script>
import BooksyService from "@/services/booksy.service";
export default {
  components: {
    WaterMark: () => import("@/components/WaterMark"),
  },
  created() {
    BooksyService.booksyMedIsReady();
  }
}
</script>


<style>
html {
   overflow-y: auto !important;
}
</style>


