import ApiService from "./api.service";
import QUERY_PARAMS from "@/constants/api";

const AuthService = {
  check: async (userId, businessId) => {
    return await ApiService.commonPOSTRequest(
      "/check/",
      {},
      {
        user_id: userId,
        business_id: businessId,
      },
      undefined,
      undefined,
      undefined,
      false
    );
  },
  register: async (userId, businessId, marketingAgreement) => {
    return await ApiService.commonPOSTRequest(
      "/register/",
      {},
      {
        user_id: userId,
        business_id: businessId,
        is_marketing_agreed: marketingAgreement
      },
      undefined,
      undefined,
      undefined,
      false
    );
  },
  preAuth: async (userId, businessId, url, code = undefined) => {
    return await ApiService.commonPOSTRequest(
      "/pre-auth/",
      {},
      {
        user_id: userId,
        business_id: businessId,
        code,
        url,
      },
      undefined,
      undefined,
      undefined,
      false
    );
  },
  auth: async (
    userId,
    businessId,
    code,
    url,
    email = undefined,
    password = undefined
  ) => {
    return await ApiService.commonPOSTRequest(
      "/auth/",
      {},
      {
        user_id: userId,
        business_id: businessId,
        code,
        url,
        email,
        password,
      },
      undefined,
      undefined,
      undefined,
      false
    );
  },
  compareAuthParams(route) {
    const businessId = localStorage.getItem(QUERY_PARAMS.BUSINESS_ID);
    const userId = localStorage.getItem(QUERY_PARAMS.USER_ID);

    if (!businessId || !userId) {
      return false;
    }

    const params = ApiService.supportedQueryParamsFromUrl(route);

    const compBusinessId = params.get(QUERY_PARAMS.BUSINESS_ID);
    const compUserId = params.get(QUERY_PARAMS.USER_ID);

    if (businessId === compBusinessId && userId === compUserId) {
      return true;
    } else {
      return false;
    }
  },
  setAuthParams(route) {
    const params = ApiService.supportedQueryParamsFromUrl(route);
    localStorage.setItem(
      QUERY_PARAMS.BUSINESS_ID,
      params.get(QUERY_PARAMS.BUSINESS_ID)
    );
    localStorage.setItem(
      QUERY_PARAMS.USER_ID,
      params.get(QUERY_PARAMS.USER_ID)
    );
  },
};

export default AuthService;
