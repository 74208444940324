const USER_FIRST_NAME = "first_name";
const USER_LAST_NAME = "last_name";
const USER_ACCESS_LEVEL = "access_level";
const USER_IS_SUPERUSER = "is_superuser";

const UserStorage = {
  getFirstName() {
    return localStorage.getItem(USER_FIRST_NAME);
  },
  saveFirstName(firstName) {
    localStorage.setItem(USER_FIRST_NAME, firstName);
  },
  removeFirstName() {
    localStorage.removeItem(USER_FIRST_NAME);
  },
  getLastName() {
    return localStorage.getItem(USER_LAST_NAME);
  },
  saveLastName(lastName) {
    localStorage.setItem(USER_LAST_NAME, lastName);
  },
  removeLastName() {
    localStorage.removeItem(USER_LAST_NAME);
  },
  getAccessLevel() {
    return localStorage.getItem(USER_ACCESS_LEVEL);
  },
  saveAccessLevel(accessLevel) {
    localStorage.setItem(USER_ACCESS_LEVEL, accessLevel);
  },
  removeAccessLevel() {
    localStorage.removeItem(USER_ACCESS_LEVEL);
  },
  getIsSuperuser() {
    return localStorage.getItem(USER_IS_SUPERUSER) === "true";
  },
  saveIsSuperuser(isSuperuser) {
    localStorage.setItem(USER_IS_SUPERUSER, isSuperuser);
  },
  removeIsSuperuser() {
    localStorage.removeItem(USER_IS_SUPERUSER);
  },
};

export default UserStorage;
