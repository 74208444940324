import ModelLocalStorage from "../local/model";

const NOTICE_UUID = "notice_uuid";
const SYNC_P1 = "sync_p1";
const VISIBLE_CARD_TYPES = "visible_card_types";
const RECOMMENDATIONS_VISIBLE = "visible_recommendations";
const ENABLED_MEDICAL_ADDON = "enabled_medical_addon";
const ACCESS_TO_MEDICAL_EMPLOYEES = "access_to_medical_employees";
const SUBSCRIPTION_ACTIVE = "subscription_active";

const state = {
  patientDetailsActiveTab: 0,
  settingsActiveTab: 0,
  noticeUUID: ModelLocalStorage.get(NOTICE_UUID, ""),
  noticeHeaderIsVisible: false,
  syncP1: ModelLocalStorage.get(SYNC_P1, false),
  visibleCardTypes: ModelLocalStorage.get(VISIBLE_CARD_TYPES, [0, 1, 2]),
  recommendationsVisible: ModelLocalStorage.get(RECOMMENDATIONS_VISIBLE, false),
  enabledMedicalAddon: ModelLocalStorage.get(ENABLED_MEDICAL_ADDON, false),
  accessToMedicalEmployees: ModelLocalStorage.get(ACCESS_TO_MEDICAL_EMPLOYEES, false),
  subscriptionActive: ModelLocalStorage.get(SUBSCRIPTION_ACTIVE, false),
  superuserAccessDenied: false,
};

const getters = {
  patientDetailsActiveTab: (state) => {
    return state.patientDetailsActiveTab;
  },
  settingsActiveTab: (state) => {
    return state.settingsActiveTab;
  },
  noticeUUID: (state) => {
    return state.noticeUUID;
  },
  noticeHeaderVisible: (state) => {
    return state.noticeHeaderIsVisible;
  },
  syncP1: (state) => {
    return state.syncP1;
  },
  visibleCardTypes: (state) => {
    return state.visibleCardTypes;
  },
  recommendationsVisible: (state) => {
    return state.recommendationsVisible;
  },
  enabledMedicalAddon: (state) => {
    return state.enabledMedicalAddon;
  },
  accessToMedicalEmployees: (state) => {
    return state.accessToMedicalEmployees;
  },
  subscriptionActive: (state) => {
    return state.subscriptionActive;
  },
  superuserAccessDenied: (state) => {
    return state.superuserAccessDenied;
  },
};

const mutations = {
  setPatientDetailsActiveTab(state, value) {
    state.patientDetailsActiveTab = value;
  },
  setSettingsActiveTab(state, value) {
    state.settingsActiveTab = value;
  },
  setNoticeUUID(state, value) {
    state.noticeUUID = value;
    ModelLocalStorage.save(NOTICE_UUID, value);
  },
  setNoticeHeaderVisible(state, value) {
    state.noticeHeaderIsVisible = value;
  },
  setProfileActiveTab(state, value) {
    state.profileActiveTab = value;
  },
  setSyncP1(state, value) {
    state.syncP1 = value;
    ModelLocalStorage.save(SYNC_P1, value);
  },
  setVisibleCardTypes(state, value) {
    state.visibleCardTypes = value;
    ModelLocalStorage.save(VISIBLE_CARD_TYPES, value);
  },
  setRecommendationsVisible(state, value) {
    state.recommendationsVisible = value;
    ModelLocalStorage.save(RECOMMENDATIONS_VISIBLE, value);
  },
  setEnabledMedicalAddon(state, value) {
    state.enabledMedicalAddon = value;
    ModelLocalStorage.save(ENABLED_MEDICAL_ADDON, value);
  },
  setAccessToMedicalEmployees(state, value) {
    state.accessToMedicalEmployees = value;
    ModelLocalStorage.save(ACCESS_TO_MEDICAL_EMPLOYEES, value);
  },
  setSubscriptionActive(state, value) {
    state.subscriptionActive = value;
    ModelLocalStorage.save(SUBSCRIPTION_ACTIVE, value);
  },
  setSuperuserAccessDenied(state, value) {
    state.superuserAccessDenied = value;
  }
};

const actions = {
  async updatePatientDetailsActiveTab({ commit }, value) {
    commit("setPatientDetailsActiveTab", value);
  },
  async reset({ commit }) {
    commit("setSettingsActiveTab", 0);
    commit("setEnabledMedicalAddon", false);
  },
  async updateSettingsActiveTab({ commit }, value) {
    commit("setSettingsActiveTab", value);
  },
  async updateNoticeUUID({ commit }, value) {
    commit("setNoticeUUID", value);
  },
  async updateNoticeHeaderVisible({ commit }, value) {
    commit("setNoticeHeaderVisible", value);
  },
  async updateSyncP1({commit}, value) {
    commit("setSyncP1", value);
  },
  async updateVisibleCardTypes({commit}, value) {
    commit("setVisibleCardTypes", value);
  },
  async updateRecommendationsVisible({commit}, value) {
    commit("setRecommendationsVisible", value);
  },
  async updateEnabledMedicalAddon({commit}, value) {
    commit("setEnabledMedicalAddon", value);
  },
  async updateAccessToMedicalEmployees({commit}, value) {
    commit("setAccessToMedicalEmployees", value);
  },
  async updateSubscriptionStatus({commit}, value) {
    commit("setSubscriptionActive", value);
  },
  async updateSuperuserAccessDenied({ commit }, value) {
    commit("setSuperuserAccessDenied", value);
  }
};

export const ui = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default ui;
