import axios from "axios";
import TokenService from "./token.service";
import UserStorage from "./userstorage.service";
import store from "@/store/index";
import router from "@/router";
import QUERY_PARAMS from "@/constants/api";
import uuid from "uuid";

const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error.response.status === 401 &&
          router.currentRoute.name !== "auth"
        ) {
          store.dispatch("auth/logout", { autologout: true }).then(
            router.replace({
              name: "auth",
              query: { redirect: router.currentRoute.fullPath },
            })
          );
        }

        if (
          error.response.status === 403 &&
          [
            "Medical addon is disabled",
            "Business has inactive subscription",
          ].includes(error.response?.data?.detail) &&
          router.currentRoute.name !== "auth"
        ) {
          store.dispatch("ui/updateEnabledMedicalAddon", false);
        }

        if (
          error.response.status === 403 &&
          error.response?.data?.detail === "Inactive subscription" &&
          router.currentRoute.name !== "auth"
        ) {
          store.dispatch("ui/updateSubscriptionStatus", false);
          router.replace({
            name: "settings",
            query: {
              ...router.currentRoute.query,
              state:
                router.currentRoute.query?.state === "customer_details"
                  ? "dashboard"
                  : router.currentRoute.query?.state,
              tab: "subscription",
              id: undefined,
              _event: uuid.v4()
            },
          });
        }

        if (
          error.response.status === 403 &&
          error.response?.data?.detail === "Superuser can't see patients data" &&
          router.currentRoute.name !== "auth"
        ) {
          store.dispatch("ui/updateSuperuserAccessDenied", true);
        }

        return Promise.reject(error);
      }
    );
  },
  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${TokenService.getToken()}`;
  },
  removeHeader() {
    axios.defaults.headers.common = {};
  },
  customRequest(data) {
    if (data.params != undefined) {
      if (data.params.rowsPerPage != undefined) {
        data.params.page_size = data.params.rowsPerPage;
        delete data.params.rowsPerPage;
        delete data.params.totalItems;
      }

      if (data.params.search != undefined) {
        let min = 3;
        if (data.params.min != undefined) {
          min = data.params.min;
        }

        if (data.params.search.length < min) {
          delete data.params.search;
        }
      }

      if (data.params.sortBy != undefined) {
        let ops = "";
        if (data.params.sortBy == "start_datetime") {
          ops = "start_date";
        } else if (data.params.sortBy == "end_datetime") {
          ops = "end_date";
        } else {
          ops = data.params.sortBy;
        }

        data.params.ordering = data.params.descending ? ops : "-" + ops;

        delete data.params.sortBy;
        delete data.params.descending;
      }
    }

    if (router.currentRoute.query) {
      if (!data.params.id) {
        data.params.id = router.currentRoute.query.id;
      }

      if (!data.params.state) {
        data.params.state = router.currentRoute.query.state;
      }

      if (!data.params.user_id) {
        data.params.user_id = router.currentRoute.query.user_id;
      }

      if (!data.params.business_id) {
        data.params.business_id = router.currentRoute.query.business_id;
      }
    }

    console.log("Request: ", data);
    return axios(data);
  },
  supportedQueryParamsFromUrl(route) {
    let queries = {};
    if (route.query.redirect) {
      const url = new URL(
        process.env.VUE_APP_URL_FRONTEND + route.query.redirect
      );
      queries = Object.fromEntries(url.searchParams);
    } else {
      queries = route.query;
    }
    const values = Object.values(QUERY_PARAMS);
    const supportedQueryParams = new Map();
    for (const query of Object.keys(queries)) {
      if (values.includes(query)) {
        supportedQueryParams.set(query, queries[query]);
      }
    }
    return supportedQueryParams;
  },
  urlFromSupportedQueryParams(route, exclude = []) {
    const queryParams = ApiService.supportedQueryParamsFromUrl(route);
    if (!queryParams) {
      return process.env.VUE_APP_URL_FRONTEND;
    }

    const url = new URL(process.env.VUE_APP_URL_FRONTEND);
    Array.from(queryParams.keys()).forEach(
      (key) =>
        queryParams.get(key) &&
        !exclude.includes(key) &&
        url.searchParams.set(key, queryParams.get(key))
    );
    return url;
  },
  async commonGETRequest(
    url,
    args = {},
    responseType = undefined,
    retData = true
  ) {
    const requestData = {
      method: "GET",
      url: url,
      params: args,
      responseType: responseType,
    };
    ApiService.setHeader();
    const reply = await ApiService.customRequest(requestData);

    if (retData) {
      return reply.data;
    } else {
      return reply;
    }
  },
  async commonPUTRequest(url, args = {}, data = {}) {
    const requestData = {
      method: "PUT",
      url: url,
      params: args,
      data: data,
    };
    ApiService.setHeader();
    const reply = await ApiService.customRequest(requestData);
    return reply.data;
  },
  async commonPOSTRequest(
    url,
    args = {},
    data = {},
    headers = undefined,
    onUploadProgress = undefined,
    cancelToken = undefined,
    setHeader = true
  ) {
    const requestData = {
      method: "POST",
      url: url,
      params: args,
      data: data,
      headers: headers,
      onUploadProgress: onUploadProgress,
      cancelToken: cancelToken,
    };

    if (setHeader) {
      ApiService.setHeader();
    }

    const reply = await ApiService.customRequest(requestData);
    return reply.data;
  },
  async commonPATCHRequest(
    url,
    args = {},
    data = {},
    headers = undefined,
    onUploadProgress = undefined,
    cancelToken = undefined
  ) {
    const requestData = {
      method: "PATCH",
      url: url,
      params: args,
      data: data,
      headers: headers,
      onUploadProgress: onUploadProgress,
      cancelToken: cancelToken,
    };
    ApiService.setHeader();
    const reply = await ApiService.customRequest(requestData);
    return reply.data;
  },
  async commonDELETERequest(url, args = {}) {
    const requestData = {
      method: "DELETE",
      url: url,
      params: args,
    };
    ApiService.setHeader();
    const reply = await ApiService.customRequest(requestData);
    return reply.data;
  },
};

export default ApiService;
